<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-10 space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Description') }}: {{ posts.description }}
                    </p>
                    <p>
                        {{ i18n('User') }}: {{ posts.person_name }}
                    </p>
                    <div v-if="posts.image" class="image-container">
                        <img
                            class="post-image"
                            :src="'/storage/' + posts.image"
                            alt="post image">
                    </div>
                </article>
            </div>
        </div>
        <a class="column is-flex is-full-mobile" @click="$router.push('/posts')">
            <button class="button butt control field">
                {{ i18n('Back') }}
                <fa class="i-icon" icon="arrow-left"/>
            </button>
        </a>
    </div>
</template>

<script>
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';

export default {
    name: 'Show',

    components: { Fa },

    inject: ['i18n', 'http', 'route', 'routerErrorHandler', 'toastr'],

    data: () => ({
        posts: {},
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http
                .get(this.route('posts.show', { post: this.$route.params.post }))
                .then(({ data }) => {
                    this.posts = data.posts;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.image-container{
    justify-content: center;
    display: flex;
}

.title-menu {
    font-size: 1.5rem;
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: $secondary;
}

.section-style{
    background: radial-gradient(circle, rgba(72,95,199,1) 35%, rgba(9,9,121,1) 100%);
    padding: 10px;
}

.titles{
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.titles1{
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}
.post-image{
    max-width: 200px;
    margin: 1rem;
}

.space{
    padding: 3px !important;
    &.btn {
        cursor: pointer;
    }
}

.butt{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

.textEmail{
    text-transform: none !important;
}

</style>
